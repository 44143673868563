export default {
	mice: {
		paging: "/api/engine/event/mice/paging", //分页查询会议
		cancel: "/api/engine/event/mice/cancel", //取消会议
		GetFascoLostFee: "/api/tenants/roche/fasco/GetFascoLostFee", //取消会议（确认提示框）
		CallListEventFee: "/api/tenants/roche/fasco/CallListEventFee", //取消会议（确认提示框）
		retract: "/api/engine/event/mice/retract", //撤回会议
		backToDraft: "/api/engine/event/mice/backtodraft", //修改会议
		remove: "/api/engine/event/mice/remove", //删除会议
		PagingProposals: '/api/engine/event/Proposal/PagingProposals', //会议列表
		PagingByMarker: '/api/tenants/roche/Employee/PagingByMarker', //会议申请者、会议组织者
		PagingPOByMarker: '/api/tenants/roche/Employee/PagingPOByMarker', //PO操作人
		GetMiceTypes: '/api/engine/event/Mice/GetMiceTypes', //会议类型
		GetBudgetProjects: '/api/tpm/cfg/querySettings', //获取预算项目
		GetEventProperties: '/api/tenants/roche/Product/GetEventProperties', //会议性质
		QueryProductGroups: '/api/tenants/roche/Product/QueryProductGroups', //产品
		SaveEvent: '/api/engine/event/Mice/Save', //创建会议、编辑
		EditMiceInfo: '/api/tenants/roche/Mice/EditMiceInfo', //创建会议、编辑
		PagingHospital: '/api/shopkeeper/hospital/PagingHospital', //上线医院
		OnLineCity: '/api/tenants/roche/Mice/OnLineCity', //上线城市
		GetWebappUrl: '/api/category/car/Order/GetWebappUrl', //地面交通费-城市车队   --- 跳转至 icar
		CancelProposal: '/api/tenants/roche/Mice/CancelProposal', //取消会议
		RetractAudit: '/api/tenants/roche/Mice/RetractAudit', //撤回审批
		RetractProposal: '/api/tenants/roche/Mice/RetractProposal', //撤回会议（修改会议）
		GetProposalDetails: '/api/tenants/roche/mice/GetEventInfo', //会议详情
		GetProjectItemCount: '/api/tenants/roche/Mice/GetProjectItemCount', //会议详情--会议申请圆圈的必填信息
		QueryAttendersByType: '/api/engine/event/Attender/QueryAttendersByType', //参会人list
		QueryMiceAttenders: 'api/tenants/roche/Attender/QueryMiceAttenders', //参会人list
		AppendAttenders: '/api/engine/event/Attender/AppendAttenders', //参会人list
		QueryAttender: '/api/tenants/roche/mice/QueryAttender', //参会人list
		ModifyAttender: '/api/engine/event/Attender/ModifyAttender', //参会人list
		checkinSave: '/api/engine/event/Checkin/Save', // 保存签到记录
		QueryInviteAttenders: '/api/engine/event/Attender/QueryInviteAttenders', //HCP邀约
		SendInvitation: '/api/engine/event/Attender/SendInvitation', //发送邀约
		AcceptInvitation: '/api/engine/event/Attender/AcceptInvitation', // 是否接受邀约
		SendInvitationForHcpBefore:'/api/engine/event/Attender/SendInvitationForHcpBefore',
		HcpInfoPagingByMarker: '/api/tenants/roche/HcpInfo/PagingByMarker', //外部参会人员
		HcpInfoPaging: '/api/tenants/roche/HcpInfo/Paging', //选择讲者
		HcpInfoPagingOftenAttender: '/api/tenants/roche/HcpInfo/PagingOftenAttender', //常用外部参会人员
		EmployeePagingByMarker: '/api/tenants/roche/Employee/PagingByMarker', //内部参会人员
		EmployeePagingOftenAttender: '/api/tenants/roche/Employee/PagingOftenAttender', //常用内部参会人员
		AttenderSave: '/api/engine/event/Attender/Save', //保存内部、外部、邮箱
		BatchSave: '/api/engine/event/Attender/BatchSave', // web端多个参会人员保存
		AttenderDelete: '/api/engine/event/Attender/Remove', //删除内部、外部、邮箱
		AttenderModify: '/api/engine/event/Attender/Modify', //保存邮箱
		QueryProposalStatus: '/api/tenants/roche/Mice/QueryProposalStatus', //获取筛选状态
		noneLoss: "/api/engine/event/loss/noneloss", //无损失
		confirmLoss: "/api/engine/event/loss/confirmloss", //确认损失
		getSpreadReport: "/api/tenants/roche/ExpenseAllocation/GetSpreadReport", // 获取分摊报告
		hasSeapkerAuth: '/api/tenants/roche/Employee/HasSeapkerAuth', //按当前登录用户获取 是否有讲者 显示权限
		customerIsOpenCount: '/api/eventworldipay/customer/customerIsOpenCount', // 打开卡片管理做权限判断
		QueryFascoSettleInfo: 'api/tenants/roche/Fasco/QueryFascoSettleInfo', // 从fasco获取机票火车票详情
		QueryItems: '/api/tenants/roche/AuditDetail/QueryItems', // 审核详情
        SimpleList: '/api/tenants/roche/Speakers/SimpleList', // 讲者列表
		GetMiceCustomData: '/api/tenants/roche/Mice/GetMiceCustomData', // 讲者列表
		CheckDataModify: '/api/tenants/roche/mice/CheckDataModify', // 提交时展示
		saveCacheData: 'api/statestore/redis/create', // 保存业务数据
		queryCacheData: 'api/statestore/redis/query', // 保存业务数据
		ValidateAttendee: '/api/tenants/roche/Mice/ValidateAttendee', // 会议执行验证签到记录是否存在内部人员
		ValidateSession: '/api/tenants/roche/Mice/ValidateSession', // 验证日程是否小于30分钟，否则提示
		getRoleCheck: '/api/tenants/roche/tenantconfig/get', //是否授权赋权（只限roche）
	},
	meal: {
		GetMealOrderUrl: "/api/category/meal/MeiTuan/GetOrderUrl", //到餐商家查询
		GetDianPingUrl: "/api/category/takeout/takeout/GetDianPingUrl", //外卖商家查询
		addEmployeeRoche: '/api/category/meal/ele/employee/AddEmployee',// 罗氏外卖
		GetHomePageUrl: '/api/category/meal/ele/EleH5/GetHomePageReadOnlyUrl',// 饿了么H5地址
	},
	miceCheckTrack: {
		MiceCheckTrackAnswer: "/api/tenants/roche/MiceCheckTrack/Answer", //问题回复
		MiceCheckTrackQuery: "/api/tenants/roche/MiceCheckTrack/QueryGroup", //查询退单记录
		MiceCheckTrackQueryList: "/api/tenants/roche/MiceCheckTrack/QueryList", //查询退单记录
		MiceCheckTrackDetail: "/api/tenants/roche/MiceCheckTrack/Detail", // 退单记录详情
	},
	miceAgentEmployee: {
		PagingMiceAgent: "/api/engine/event/Mice/PagingMiceAgent", //代理提名名下的会议
		PagingAgentEmployee: "/api/tenants/roche/Employee/PagingAgentEmployee", //查询所有可选择的代理提名人员列表
		QueryAgentEmployee: "/api/engine/event/MiceAgentEmployee/QueryAgentEmployee", //
		pageAgents: 'api/engine/event/MiceAgentEmployee/PageAgents', // 授权管理列表
		Remove: "/api/engine/event/MiceAgentEmployee/Remove", // 删除代理提名名单
		Save: "/api/engine/event/MiceAgentEmployee/Save", //保存代理提名名单
		PagingAgentAttender: "/api/tenants/roche/HcpInfo/PagingAgentAttender", //代理提名选择外部参会人
        BatchSave: "/api/engine/event/MiceAgentEmployee/BatchSave", // 批量保存
		UpdateReadFlag: '/api/tenants/roche/Agent/UpdateReadFlag', //更改会议是否已查看状态
	},
	// 上会人员授权 
	AttendeesAuthority: { 
		GetAssistantListByMiceId: "/api/category/worker/Assistant/GetAssistantListByMiceId",// 查询已授权
		GetWorkListByMiceId: "/api/category/worker/Assistant/GetWorkListByMiceId",// 获取上会人员
		Save: "/api/category/worker/Assistant/Save",// 保存授权
		Delete: "/api/category/worker/Assistant/Delete",// 删除授权
		GetUserToken: "/api/category/car/Order/GetUserToken",//查询神州授权
		GetTokenUrl: "/api/category/car/Order/GetTokenUrl",//获取神州授权地址

	},
	flowTask: {
		progress: "/api/foundation/workflow/fnflowtask/gettasklogs", //获取进程
		QueryTaskActions: '/api/foundation/workflow/FnFlow/QueryTaskActions', //获取流程按钮 taskId
		ProcessTask: '/api/foundation/workflow/FnFlowTask/ProcessTask', //流转
		CreateTask: "/api/foundation/workflow/FnFlowTask/CreateTask", //流程引擎
	},
	session: {
		query: '/api/engine/event/session/query', //查询议程
		save: '/api/engine/event/session/save', //保存议程
		InvokeMethod: '/xchief/api/xmeta/InvokeMethod', //操作议程
		delete: '/api/engine/event/session/delete', //删除议程(废弃)
		remove: '/api/engine/event/session/remove', //删除议程
		SaveBath: '/api/engine/event/session/SaveBath', //批量保存议程
        historyList: '/api/engine/event/session/VersionList', //日程版本列表
        historyDetail: '/api/engine/event/session/VersionInfo' //日程版本明细
	},
	budget: {
		query: '/api/tenants/roche/Budget/Query', //预算list
		GetItemGroups: '/api/tenants/roche/Budget/GetItemGroups', //需求list
		GetMiceBudgets: '/api/tenants/roche/Budget/GetMiceBudgets', //预算list
		QueryMiceDetail: '/api/tenants/roche/Budget/QueryMiceDetail', //上会服务人员列表
		save: '/api/tenants/roche/Budget/Save', //添加预算
		delete: '/api/tenants/roche/Budget/Delete', //删除预算
		deleteAll: '/api/tenants/roche/Budget/MultiDeleteById', //删除预算
		CreateBudgetExpense: '/api/tenants/roche/Budget/CreateBudgetExpense', //创建拆分预算信息
		BatchQuery: '/api/tenants/roche/AppendCategory/BatchQuery', //查询批次
		BatchDelete: '/api/tenants/roche/AppendCategory/BatchDelete', //删除追加批次
		OrderQuery: '/api/tenants/roche/AppendCategory/OrderQuery', //查询追加品类订单
		QuoteQuery: '/api/tenants/roche/AppendCategory/QuoteQuery', //查询追加报价订单
		OrderDelete: '/api/tenants/roche/AppendCategory/OrderDelete', //删除追加订单
		SaveExpense: '/api/tenants/roche/AppendCategory/SaveExpense', //保存追加品类
		DeleteOrderByBatch: '/api/tenants/roche/AppendCategory/DeleteOrderByBatch', //删除指定批次下所有订单
		OrderExpenseDetail: '/api/tenants/roche/AppendCategory/OrderExpenseDetail', //查询订单详情
		StartFlow: '/api/tenants/roche/flow/StartFlow', //提交审批
		ValidAppendOrder: '/api/tenants/roche/flow/ValidAppendOrder',// 是否可以新增订单
		QuerySelectedBudgetItems: '/api/tenants/roche/dict/QuerySelectedBudgetItems', //已选择预算项目
		QueryBudgetItemCategorys: '/api/tenants/roche/dict/QueryBudgetItemCategorys', //已选择预算项目
		QuerySelectedSuppliers: '/api/tenants/roche/dict/QuerySelectedSuppliers', //已选择预算项目
		ExpenseDelete:'/api/tenants/roche/AppendCategory/ExpenseDelete',//删除报价
	},
	expenseAllocation: {
		paging: '/api/tenants/roche/ExpenseAllocation/Paging', //费用分摊list
		save: '/api/tenants/roche/ExpenseAllocation/Save', //添加费用分摊
		delete: '/api/tenants/roche/ExpenseAllocation/Delete', //删除费用分摊
	},
	dictionary: {
		query: '/foundation/dict/api/FnDictionary/Query', //会议时段 | 科室
		paging: '/foundation/dict/api/FnDictionary/Paging', //费用分摊--成本中心+ WBS Code
		gostCenterPaging: '/api/tenants/roche/CostCenter/Paging', //
		gostCenterPagingA1: 'api/jsrv-roche/cost/getCostCenter', //费用分摊--成本中心
		gostWbsPagingA1: '/api/jsrv-roche/cost/getwbs', //费用分摊--WBS Code
		QueryRegion: '/api/shopkeeper/Region/QueryRegion' //省份，城市查询
	},
	identity: {
		// getme:'/api/identity/account/getme'//个人资料
		getEmployee:'/api/tenants/roche/employee/get',//个人资料
		getme: '/foundation/passport/IUserService/GetMe', //个人信息
        clearAlipayId: '/api/foundation/user/clearAlipayId', //清除授权
        UnbundingAliPayUID: '/api/category/meal/ele/Employee/UnbundingAliPayUID', //清除授权
	},
	Checkin: {
		Save: '/api/engine/event/Checkin/Save', //保存签到信息
		CancelCheckin: '/api/engine/event/Checkin/CancelCheckin', //取消签到
		QueryCheckinAttenders: '/api/tenants/roche/Attender/QueryCheckinAttenders', //查询签到信息
		ScanCheckIn: '/api/engine/event/Checkin/ScanCheckIn', //扫描签到
		GetUserConsent: 'api/tenants/roche/Esr/GetUserConsent  ', // 通过电话号码查询参会人信息
		QueryNoCrmInfos: 'api/tenants/roche/fasco/QueryNoCrmInfos', // 通过电话号码查询参会人信息
		BatchCheckinNoSaveAttender: 'api/engine/event/Attender/BatchCheckinNoSaveAttender', // 批量保存待签到人员
	},
	lecturesDraft: {
		Query: '/api/tenants/roche/PerFormance/Query', // 查询(履行文件)
		Modify: '/api/tenants/roche/PerFormance/Modify', // 修改(履行文件)
        PmaModify: '/api/tenants/roche/PerFormance/PmaModify', // 修改演讲文稿(履行文件)
		Save: '/api/tenants/roche/PerFormance/Save', // 保存(履行文件)
        Detail: '/api/tenants/roche/Speakers/QueryList', // 讲课费明细
		queryPmaInfos: 'api/tenants/roche/PerFormance/QueryPmaInfos', // 获取pmcode
		PagePmaInfos: 'api/tenants/roche/PerFormance/PagePmaInfos', // 获取pmcode(分页)
		QuerySpeechPpts: '/api/tenants/roche/PerFormance/QuerySpeechPpts', // 获取已保存pmcode
	},
	message: {
		Query: "/api/service/notification/MqMessagePushLog/Paging", // 消息查询
		Read: "/api/service/notification/MqMessagePushLog/Read", // 修改未读已读状态
		Delete: "/api/service/notification/MqMessagePushLog/Delete", // 删除
		GetInvitation: "/api/engine/event/Attender/GetInvitation", // 获取邀约会议详情
	},
	category: {
		GetStaffsManager: '/api/category/worker/Staffs/GetStaffsManager', //前期人员
        StaffsQuery: '/api/engine/event/Mice/QueryWorkers', //现场人员
        QueryItems: '/api/tenants/roche/CustomCategory/QueryItems', // 5W需求列表
        SaveItem: '/api/tenants/roche/CustomCategory/SaveItem', // 5W保存需求
        QueryItemDetail: '/api/tenants/roche/CustomCategory/QueryItemDetail', // 5W需求详情
        RemoveItem: '/api/tenants/roche/CustomCategory/RemoveItem', // 5W删除需求
        SaveExpense: '/api/tenants/roche/CustomCategory/SaveExpense', // 5W保存费用
        RemoveExpense: '/api/tenants/roche/CustomCategory/RemoveExpense', // 5W删除费用
        QueryVendors: '/api/tenants/roche/vendor/Query', // 5W以上供应商列表
	},
	userOftenAttender: {
		Save: '/api/engine/event/UserOftenAttender/Save', //保存常用名单
		Remove: '/api/engine/event/UserOftenAttender/Remove', //删除常用名单
		Query: '/api/engine/event/UserOftenAttender/QueryOftenAttender', //查询常用名单
		UpdateInfo: '/api/engine/event/UserOftenAttender/UpdateInfo' //更新常用参会人名单
	},
	APPScore:{
		BulkCreate:'/api/engine/event/MiceScore/BulkCreate',
		QueryScoreConfig:'/api/engine/event/MiceScore/QueryScoreConfig'
	},
	disclaimer: {
		GetAttenderDisclaimer: "/api/tenants/roche/Disclaimer/GetAttenderDisclaimer", //获取免责声明内容
		AcceptDisclaimer:"/api/tenants/roche/Disclaimer/AcceptDisclaimer"//同不同意免责声明
	},
	SYT: {
		checkSYTAuthed: '/api/external/syt/smartmice/checkSYTAuthed', // 商宴通支付宝授权验证
	},
	meeting: {
		mettingPriceQuery: '/api/resource/video/MettingPrice/Query', // 视频会议类型
	},
	tpm:{
		querysettings: '/api/tpm/cfg/querysettings', // return Array
		getsettings: '/api/tpm/cfg/getsettings',   //return Object
		exists: '/api/tpm/cfg/exists', // 配置return Boole
		QuerySettings: 'xchief/api/xconfig/QuerySettings',
    },
    oss: {
        delete: "/foundation/aliyun/api/oss/delete"
    },
	video: {
		RemarkQuery: 'api/resource/video/Remark/Query', // 获取会议室备注
		MettingPriceQuery: 'api/resource/video/MettingPrice/Query', // 获取会议室类型
	},
	settle: {
		GetConfirmTask: `api/category/venue/SupplierExpense/GetConfirmTask`, // get 获取otherList
		QueryTaskActions: "api/foundation/workflow/FnFlow/QueryTaskActions", // post  获取按钮
		ProcessTask: "api/foundation/workflow/FnFlowTask/ProcessTask", // post  获取按钮
	},
	// 离职转单
	quit: {
		PagingConvertLog: 'api/tenants/roche/Mice/PagingConvertLog', //转单记录
		ListConvertMices: 'api/tenants/roche/Mice/ListConvertMices', // 转单记录会议列表
		PagingAuthEmployee: 'api/tenants/roche/employee/PagingAuthEmployee', // 分页查询可转单员工
		ListCanConvertMices: 'api/tenants/roche/mice/ListCanConvertMices', // 查询可转单会议列表
		AuthorizeEmployee: 'api/tenants/roche/Employee/AuthorizeEmployee', // 转单
	},
	// 授权管理
	authorization: {
		pageAgents: 'api/engine/event/MiceAgentEmployee/PageAgents', // 授权管理列表
		getUndoTaskCount: 'api/tenants/roche/Menu/GetUndoTaskCount', // 未读授权任务数量
		pagingEmployeeByEmailGroup: 'api/tenants/roche/Employee/PagingEmployeeByEmailGroup', // 可授权列表
		batchSave: 'api/engine/event/MiceAgentEmployee/BatchSave', // 保存授权
		Remove: "/api/engine/event/MiceAgentEmployee/RemoveMany", // 删除代理提名名单
		GetWorkerLocCondition: 'api/tenants/roche/WorkerLoc/GetWorkerLocCondition', //获取会议授权搜索条件列表
		PagingWorkerLocInfo: 'api/tenants/roche/WorkerLoc/PagingWorkerLocInfo', // 可授权列表
		MemberUnBind: 'rpc/pay/Alipay/MemberUnBind', //清除支付授权
	},
	meiTuan: {
		meiTuanLogin: 'api/category/meal/meituan/shop/login', // 美团到餐地址
	},
	xquery: {
		GetQueryData: 'xchief/api/xquery/GetQueryData', // 获取数据字典
	}
}

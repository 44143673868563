<template>
  <section>
    <div class="search_container">
      <span class="search_btn"><i slot="search" class="icon_event_search"></i></span>
      <input  @input="changeModel" v-model="val" :id="'SearchRow'+tag" type="search" class="search_input" :placeholder="placeholder" />
      <transition name="searchicon">
				<span v-if="val" class="search_delete_btn" @click="deleteInput()">
					<i slot="search" class="icon_search_delete_v2"></i>
				</span>
      </transition>
    </div>
  </section>
</template>

<script>
    export default {
        name: 'SearchRow',
        props:[
            'placeholder',//默认文字
            'value',//绑定字段
        ],
        data() {
            return {
                val: '',
                tag: '',
                timer: ''
            }
        },
        watch: {
            'value': function () {
                this.val = this.setInputValue;
            }
        },
        created() {
            let math = Math.random().toString().replace('0.', '');
            let date = new Date().getTime();
            this.tag = math + date;
            this.val = this.setInputValue
        },
        computed: {
            setInputValue() {
                return this.value;
            }
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        methods: {
            changeModel() {
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                let _this = this;
                this.timer = setTimeout(function () {
                    _this.$emit('change', _this.val);
                    _this.$emit('search-call', _this.val);
                }, 500);
            },
            deleteInput() {
                this.val = '';
                this.$emit('change', this.val);
                this.$emit('search-call', this.val);
            }
        }
    }
</script>

<style scoped="scoped">
  .search_container {
    width: 100%;
    height: .52rem;
    line-height: .52rem;
    text-align: center;
    background-color: white;
    position: relative;
  }

  .search_input {
    position: absolute;
    /*right: 2.8%;*/
    left: 3%;
    top: 0.07rem;
    width: 94%;
    height: .38rem;
    display: inline-block;
    border-radius: 0.07rem;
    border: none;
    background-color: #F4F4F4;
    padding-left: .44rem;
    padding-right: .35rem;
    color: #666666;
    outline: none;
    font-size: 0.14rem;
    z-index: 30;
  }

  .search_input_more {
    width: 40%;
  }

  .search_input:focus {
    border: 1px solid #4C9AFF;
  }

  .search_btn {
    position: absolute;
    box-sizing: border-box;
    top: 0rem;
    left: 3%;
    width: .4rem;
    height: .52rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
  }

  .search_delete_btn {
    position: absolute;
    box-sizing: border-box;
    top: 0rem;
    left: calc(97% - 0.35rem);
    width: .35rem;
    height: .52rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
  }

  .search_delete_btn_more {
    left: calc(43% - 0.35rem);
  }
  /*TODO*/

  .search_input::-webkit-input-placeholder {
    color: #999999;
    font-size: .14rem;
    line-height: 1em;
    padding-left: 10px;
    text-align: left;
  }

  .search_input:-moz-placeholder {
    color: #999999;
    font-size: .14rem;
    line-height: 1em;
    padding-left: 10px;
    text-align: left;
  }

  .search_input::-moz-placeholder {
    color: #999999;
    font-size: .14rem;
    padding-left: 10px;
    text-align: left;
    line-height: 1em;
  }

  .search_input:-ms-input-placeholder {
    color: #999999;
    font-size: .14rem;
    line-height: 1em;
    padding-left: 10px;
    text-align: left;
  }

  @keyframes searchicon-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes searchicon-hide {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
</style>

<template>
    <div id="app">
        <happy-new-year v-model="isShow"></happy-new-year>
        <router-view></router-view>
    </div>
</template>

<script>
import ieventPolicy from "@/policy/ievent.js";

export default {
    name: "App",
    mixins: [ieventPolicy],
    data() {
        return {
            isShow: false,
            isEventEditor: false,
            tenantColor: {
                roche: "#2D82F0",
            },
        };
    },
    updated() {
        if (this.$route.path == "/CreateEvent") {
            this.isEventEditor = true;
        } else {
            this.isEventEditor = false;
        }
    },
    created() {
        //			await this.getApiVersion();
        this.initData();
        this.getThem();
        this.editLoadElementZIndex();
        this.getRoleCheck();
    },
    methods: {
        async getRoleCheck() {
            let res = await this.api.get(this.url.mice.getRoleCheck);
            if (!res.content) {
                this.$router.push("/error");
            }
        },
        getThem() {
            let tenant = this.SmartStorage.get("tenant");
            let style = document.createElement("style");
            let tenantColor = this.tenantColor[tenant] || this.tenantColor.primary;
            style.innerHTML = ".popup_confirm{color:" + tenantColor + "}.popup_cancle{color:" + tenantColor + "}";
            document.getElementsByTagName("head")[0].appendChild(style);
        },
        initData() {
            this.$nextTick(() => {
                this.isShowHappyNewYear();
                if (document.querySelectorAll(".mint-datetime")) {
                    document.querySelectorAll(".mint-datetime").forEach((el) => {
                        el.addEventListener(
                            "touchmove",
                            function (e) {
                                e.preventDefault();
                            },
                            false
                        );
                    });
                }
            });
            this.SmartStorage.set("_screen_height", document.documentElement.clientHeight || window.innerHeight);
            !this.SmartStorage.get("eventMsg") && !this.$route.query.rad && this.$router.push("/index"); //第一次进入重定向到index
        },
        isShowHappyNewYear() {
            //春节期(2月4日-2月10日)
            let currentDate = this.formatDate(new Date());
            //let startDate = this.formatDate("2019-02-04");
            //new Date(currentDate) >= new Date(startDate) &&
            let endDate = this.formatDate("2019-02-10");
            if (new Date(currentDate) <= new Date(endDate)) {
                this.isShow = true;
            }
        },
        //修改加载层级【默认的loading圈圈的层级不足，导致一些popup页面的加载效果没有呈现】
        editLoadElementZIndex() {
            setTimeout(() => {
                if (document.getElementsByClassName("loadingOut")[1]) {
                    const loadBox = document.getElementsByClassName("loadingOut")[1];
                    const shadeBox = document.getElementsByClassName("loadingOut")[1].parentNode.children[1];
                    loadBox.style.zIndex = "50000";
                    shadeBox.style.zIndex = "51000";
                }
            }, 2000);
        },
    },
};
</script>

<style>
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #666666;
    font-size: 0.14rem;
}

*,
body {
    margin: 0px;
    padding: 0px;
}

html {
    background-color: #f2f2f2;
    -webkit-text-size-adjust: 100%;
}

.mint-header-title {
    height: 0.16rem;
    line-height: 0.16rem;
    font-size: 0.15rem;
}

.mint-search {
    height: auto;
}
.mint-tab-item-label {
    font-size: .14rem;
}
.search_input {
    font: revert;
}
</style>

<template>
	<section class="iNoticeBox" :style="{'z-index': isShow ? zIndex : 0 }">
		<transition name="dialog">
			<div v-if="isShow" class="tipsBox" :style="{'z-index': zIndex + 10, width: `${width}%`}">
				<div class="title" v-html="title"></div>
				<div class="content" v-html="content"></div>
				<div class="btnBox" v-if="isShowBtn">
					<template v-for="(item, index) in buttons">
						<p @click="clickBtn(item)" class="customBtn" :key="index"
							:style="{ width: `${(1/buttons.length)*100}%`, borderRight: index != buttons.length-1 ? '1px solid #EEEEEE' : '' }">
							{{ item.buttonTxt }}
						</p>
					</template>
				</div>
			</div>
		</transition>
		<div v-if="isShow" :style="{'z-index': zIndex}" class="shade"  @click.stop="closeOnClick()"></div>
	</section>
</template>

<script>
	export default {
		model: {
			prop: 'visible',
			event: "dialogListener"
		},
		props: {
			visible: { //控制弹出、关闭
				type: Boolean,
				default: false
			},
			width: {
				type: String || Number,
				default: 70
			},
			title: { //提示
				type: String,
				default: "<p>提示</p>"
			},
			content: {
				type: String,
				default: ""
			},
			isShowBtn: {
				type: Boolean,
				default: true
			},
			// 操作按钮
			buttons: {
				type: Array,
				default: () => [{
					txt: "我知道了",
					code: "hasRead"
				}]
			},
			closeModal: { //是否点击遮罩关闭iMessage
				type: Boolean,
				default: false
			}
		},
		watch: {
			visible() {
				this.isShow = this.visible;
			},
			isShow() {
				this.$emit("dialogListener", this.isShow);
			}
		},
		data() {
			return {
				isShow: false,
				zIndex: 30000
			}
		},
		created() {
			this.isShow = this.visible;
		},
		methods: {
			render(options) {
				Object.assign(this.$props, options);
				this.isShow = true;
			},
			clickBtn(item) {
				this.$emit("onHandle", item);
			},
			//是否点击遮罩关闭messageBox
			closeOnClick() {
				if(this.closeModal) {
					this.isShow = false;
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	.iNoticeBox {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		.tipsBox {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			align-content: center;
			width: 70%;
			height: auto;
			min-height: 10%;
			background-color: white;
			font-size: 0.14rem;
			box-sizing: content-box;
			border-radius: .1rem;
			position: relative;
			.title {
				width: 100%;
				height: 0.5rem;
				text-align: center;
				line-height: 0.6rem;
				color: #333333;
				font-size: 0.16rem;
				font-weight: 600;
			}
			.content {
				width: 100%;
				box-sizing: border-box;
				min-height: 10%;
				max-height: 66vh !important;
				overflow: scroll;
				-webkit-overflow-scrolling: touch;
				padding: 0.05rem 0.2rem 0.2rem 0.2rem;
				line-height: 0.27rem;
				white-space: normal;
				word-break: break-all;
				color: #666666;
				text-align: justify;
				text-justify:inter-ideograph;
				/*text-indent: 2em;*/
			}
			.btnBox {
				height: 0.44rem;
				line-height: 0.44rem;
				width: 100%;
				text-align: center;
				border-top: .01rem solid #EEEEEE;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				.customBtn {
					color: #2D82F0;
				}
			}
		}
		.shade {
			position: fixed;
			top: 0%;
			left: 0%;
			width: 100%;
			height: 100%;
			background: radial-gradient(circle, #333, #000, #000);
			-moz-opacity: 0.5;
			opacity: 0.50;
			filter: alpha(opacity=70);
		}
		.dialog-enter-active {
			animation: dialog-show 0.4s;
		}
		.dialog-leave-active {
			animation: dialog-hide 0.2s;
		}
		@keyframes dialog-show {
			from {
				transform: scale(1.5);
				opacity: 0;
			}
			to {
				transform: scale(1);
				opacity: 1;
			}
		}
		@keyframes dialog-hide {
			from {
				transform: scale(1);
				opacity: 1;
			}
			to {
				transform: scale(0);
				opacity: 0;
			}
		}
	}
</style>

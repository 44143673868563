import { SmartHttp, SmartStorage } from "smart-core-util";
import { Toast } from 'mint-ui';
import { indicator } from 'smart-indicator';
import * as tinyCookie from 'tiny-cookie'
import myCenter from "@/scripts/myCenter";
const $ajax = new SmartHttp({
	baseURL: process.env.VUE_APP_GATEWAY,
	alert: Toast,
	block: indicator.open, //开启loading
	unblock: indicator.close, //关闭loading
	handle401: function() {
		myCenter.logoutAsync(401)
	}
}).request;
export default class GetApiVersion {

	static install() {
		return new Promise(function(resolve, reject) {
			let params = {
				collection: "cfg-api-version",
				filter: {
					platform: "app",
					tenantCode: tinyCookie.getCookie("tenant"),
					userIds: {
						$in: [tinyCookie.getCookie("userId"), "*"]
					}
				},
				projection: {
					apiVersion: 1,
					userIds: 1
				},
				sort: {
					sort: 1
				}
			}
			$ajax.post("/api/tpm/cfg/getsettings", params).then(response => {
				if(response && response.success) {
					resolve(response)
					SmartStorage.set("apiVersion", response.content.apiVersion)
					tinyCookie.setCookie("apiVersion", response.content.apiVersion)
				}else {
					resolve(response)
				}
			}).catch(error => {
				resolve(error)
			});
		})
	}

}

import { render, staticRenderFns } from "./iNotice.vue?vue&type=template&id=afec3826&scoped=true"
import script from "./iNotice.vue?vue&type=script&lang=js"
export * from "./iNotice.vue?vue&type=script&lang=js"
import style0 from "./iNotice.vue?vue&type=style&index=0&id=afec3826&prod&scoped=scoped&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afec3826",
  null
  
)

export default component.exports
import { SmartHttp } from "smart-core-util";
import {getCookie} from 'tiny-cookie'
import myCenter from "@/scripts/myCenter";
const $ajax = new SmartHttp({
	baseURL: process.env.VUE_APP_GATEWAY,
	handle401: function() {
		myCenter.logoutAsync(401)
	}
}).request;

export default class TPMService {
	constructor() {}

	static get() {
		return new Promise(function(resolve, reject) {
			let params = {
                // db: "",
				collection: "cfg-ievent-notice",
				filter: {
					tenantCode: getCookie("tenant")
				},
				projection: {
					show: 1,
					title: 1,
					content: 1,
					actions: 1,
					isShowBtn: 1
				}
			}
			$ajax.post("/api/tpm/cfg/getsettings", params).then(response => {
				if(response && response.success) {
					resolve(response)
				} else {
					reject(response)
				}
			}).catch(error => {
				reject(error)
			});
		})
	}
}

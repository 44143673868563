// filters/index.js
/* eslint-disable */
import Vue from 'vue'
import formatDataTime from './formatDataTime'


// 开发环境注入token
try {
    if (devUser) {
        console.log("====前端发现devUser", devUser);
        document.cookie = `token=${devUser.access_token}; path=/`;
        document.cookie = `version=1; path=/`;
        let uris = `{"Uris":{"passport":"https://passport-dev.roche.com.cn","gateway":"https://a1-gateway-dev.roche.com.cn","engine-api":"https://a1-gateway-dev.roche.com.cn","smartalipay":"https://a1-smartalipay-dev.roche.com.cn","signalr":"https://signalr-dev.smartmice.cn","webapp-iaccount":"https://a1-iaccount-dev.roche.com.cn","webapp-ievent":"https://a1-ievent-dev.roche.com.cn","webpart-ievent":"https://a1-ievent-webparts-dev.roche.com.cn ","webapp-imeal":"https://a1-imeal-dev.roche.com.cn","webapp-iwaimai":"https://a1-iwaimai-dev.roche.com.cn","webapp-mshop":"https://a1-mshop-dev.roche.com.cn","webapp-qshop":"http://a1-qshop-dev.roche.com.cn","webapp-ivenue":"https://a1-ivenue-dev.roche.com.cn","webapp-ivideo":"https://a1-ivideo-dev.roche.com.cn","webapp-icar":"https://a1-icar-dev.roche.com.cn","webapp-ijob":"https://a1-ijob-dev.roche.com.cn","webapp-ihelper":"https://a1-ihelper-dev.roche.com.cn","webapp-imyprofile":"https://a1-imyprofile-dev.roche.com.cn","webapp-idelegate":"http://a1-idelegate-dev.roche.com.cn","webapp-ishop":"http://a1-rshop-dev.roche.com.cn","signalrV2":"https://signalr-dev.smartmice.cn"}}`
        document.cookie = `Uris=${uris}; path=/`;
    }
} catch (error) {

}

Object.keys(formatDataTime).forEach(key => {
    Vue.filter(key, formatDataTime[key])
})
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		attendeesAuthority:{//上会人员授权
			radioResult: [],
			BeginTime: '',
			EndTime: '',
			Permission: 0,
			Assistant: []
		},
		keyword:'',
		permission:{
			budgetItem:true,//添加预算
			costItem:true,//费用分摊
			costItemTwo:true,//费用分摊
			eventInfo:true,//会议基本信息  --控制会议是否可编辑  其余都是控制子模块是否可编辑、添加权限
			sessionItem:true,//会议日程
			attenderItem:true,//参会人员
			inviteItem:true,//邀约
			checkinItem:true,//签到
			performenceItem:true,//履行文件
			agentEmployeeItem:true,//代理提名
			agentCarItem:true,//用车授权
			quoteItem:true,//需求报价
			settleItem:true,//需求结算
			workerItem:true,//查看上会服务人员
			ticketItem:true,//机票火车票 -- 需求之后是否可编辑
			hhkWorkerItem:true,//上会服务人员服务费 -- 需求之后是否可编辑
			otherWorkerItem:true,//其他费用 -- 需求之后是否可编辑
			platFormCarWorkerItem:true,//平台车队 -- 需求之后是否可编辑
			onlineCarWorkerItem: true,//滴滴出行 -- 需求之后是否可编辑
			uciReport:true,//uci分摊报告
			sscRebackItem:true,//退单记录
            lectureFeeAuth: true // 讲课费授权
		},
		newEventForm: {},
		editEventForm:{},
		cityForm:{
			provinceId:'',//省份id
			cityId:'',//为了能获取上线医院
		},
		proposalId:'',//暂存获取会议详情id
		eventStatusItem:{},//筛选
		inviterForm:{},
		wayList:[],//选择方式
		expiryTime:'',//邀约报名截止时间
		serviceList:[],//上会服务人员
		costForm:{},//成本分摊
		messageDetail:'', // 消息详情
		personal:'', // 个人信息
		notReadTotal:'', // 未读消息总数
		calendarList:[],//缓存日程数组
		hospitalForm: {}, //选择外部参会人选择医院
    hospitalDept: '', //选择外部参会人选择科室

        speakerDetail: {}, // 讲者明细
	},
	mutations: {
		SETHOSPITALFORM(state,value){
			if(!value){
				state.hospitalForm = {};
				return false;
			}
			for(var key in value){
				state.hospitalForm[key] = value[key];
			}
		},
		SETDEPTFORM(state,value){
			if(!value){
				state.hospitalDept = '';
				return false;
			}
      state.hospitalDept = value;
		},
		RESETCALENDARLIST(state,value){
			state.calendarList = []
		},
		//缓存日程
		SEAVECALENDARLIST(state,value){
			state.calendarList = value;
		},
		//编辑日程
		EDITCALENDARLIST(state,value){
			state.calendarList[value.index] = value.item;
		},
		//新增日程
		ADDCALENDARLIST(state,value){
			state.calendarList.push(value)
		},
		//删除日程
		REMOVECALENDARLIST(state,index){
			state.calendarList.splice(index,1)
		},
		resetCostForm(state,value){
			state.costForm = {}
		},
		setCostForm(state, value){
			for(var key in value){
				state.costForm[key] = value[key];
			}
		},
		//控制pv权限
		setPermission(state, value){
			for(var key in state.permission){
				state.permission[key] = value[key] == undefined ? false : value[key];
			}
		},
		setKeyWord(state, value){
			state.keyword = value;
		},
		//新建会议
		setNewEventForm(state, value) {
			for(var key in value){
				state.newEventForm[key] = value[key];
			}
		},
		//编辑会议
		setEditEventForm(state, value) {
			state.editEventForm = value;
		},
		//重置
		resetNewEventForm(state, value){
			state.newEventForm = {};
			state.editEventForm = {}
		},
		setCityForm(state,value){
			if(!value){
				state.cityForm = {
					provinceId: '',
					cityId: '',
				}
				return false;
			}
			state.cityForm = {
				provinceId:value.provinceId,//省份id
				cityId:value.cityId,//为了能获取上线医院
			}
		},
		setProposalId(state, value){
			state.proposalId = value;
		},
		setEventStatusItem(state,value){
			state.eventStatusItem = value;
		},
		setInviterForm(state,value){
			for(var key in value){
				state.inviterForm[key] = value[key];
			}
		},
		resetInviterForm(state,value){
			state.inviterForm = {}
		},
		setWayList(state,value){
			state.wayList = value;
		},
		setInviteDate(state,value){
			state.expiryTime = value;
		},
		setServiceList(state,value){
			state.serviceList = value;
		},
		setMessageDetail(state,value){
			// console.log(value);
			state.messageDetail = value;
		},
		setPersonal(state,value){
			state.personal = value;
		},
		setNotReadTotal(state,value){
			state.notReadTotal = value;
		},
        setSpeakerDetail(state,value) {
            state.speakerDetail = value;
        },
        setAttendeesAuthority(state,value) {
            state.attendeesAuthority = value;
        },
        resetAttendeesAuthority(state) {
            state.attendeesAuthority = {
				radioResult: [],
				BeginTime: '',
				EndTime: '',
				Permission: 0,
				Assistant: []
			};
        }
	},
	
    getters: {
        attendeesAuthority: state => state.attendeesAuthority,
    }
})
export default store

/**
 * 时间样式格式化
 */
export default{
  // 格式化日期
  dataFormat(str){
      let s=str.substring(0, 10);
      return s
  }
}

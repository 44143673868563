import iSearchRow from "./iSearchRow.vue";

export default {
  install(Vue) {
    if(typeof window !== 'undefined' && window.Vue) {
      Vue = window.Vue;
    }
    Vue.component('ievent-searchRow', iSearchRow)
  }
}

import { SmartHttp, SmartStorage } from "smart-core-util";
import {getCookie, setCookie} from 'tiny-cookie'
import {Toast} from "mint-ui";
import {indicator} from "smart-indicator";
import myCenter from "@/scripts/myCenter";
var enviroment = process.env.VUE_APP_ENV; // 线上
const $ajax = new SmartHttp({
  baseURL: process.env.VUE_APP_GATEWAY,
  alert: Toast,
  block: indicator.open, //开启loading
  unblock: indicator.close, //关闭loading
  handle401: function() {
      myCenter.logoutAsync(401)
  }
}).request;
export default class AppInitialization {
  static install() {
    this.setiDomain();
    this.urlParamToStorage();
    return new Promise(function (resolve) {
      let tenantId = getCookie('tenant_id') || SmartStorage.get("tenant_id");
      let container = getCookie("container") || SmartStorage.get("container")
      let params = {
        collection: "cfg-ievent-initialization",
        filter: {
          tenantId: tenantId,
          container: ['h5'].includes(container) ? 'h5' : ''
        },
        projection: {
          _id: 0,
        },
      };
      $ajax.post('api/tpm/cfg/getsettings', params).then(response => {
        if(response && response.success && response.content) {
          let config = response.content.config || {}
          AppInitialization.setTheme(config.themeColors)
          AppInitialization.setTitle(config.titleTxt)
          let isGray = window.location.href.includes("pilot") ? config.grayUris : config.uris
          AppInitialization.setUris(isGray)
        } else {
          AppInitialization.setDefault()
        }
        resolve(response)
      }).catch(error => {
        AppInitialization.setTheme([
          {"attribute": "--themeColor", "value": "#3875FE"},
          {"attribute": "--clickColor", "value": "#4F7CC4"},
          {"attribute": "--gradualColor", "value": "#4479F1"},
          {"attribute": "--shadowColor", "value": "#3875FECA"},
          {"attribute": "--lineColor", "value": "#3875FE"}
        ])
        resolve(error)
      })
    })
  }

  static setDefault() {
    AppInitialization.setTheme([
      {"attribute": "--themeColor", "value": "#3875FE"},
      {"attribute": "--clickColor", "value": "#4F7CC4"},
      {"attribute": "--gradualColor", "value": "#4479F1"},
      {"attribute": "--shadowColor", "value": "#3875FECA"},
      {"attribute": "--lineColor", "value": "#3875FE"}
    ])
    AppInitialization.setTitle()
    let _uris = ['development'].includes(enviroment)  ?
        {
          "passport": "https://passport-test.roche.com.cn",
          "gateway": "https://a1-gateway-test.roche.com.cn",
          "engine-api": "https://a1-gateway-test.roche.com.cn",
          "smartalipay": "https://a1-smartalipay-test.roche.com.cn",
          "signalr": "https://signalr-dev.smartmice.cn",
          "webapp-iaccount": "https://a1-iaccount-test.roche.com.cn",
          "webapp-ievent": "https://a1-ievent-test.roche.com.cn",
          "webpart-ievent": "https://a1-ievent-webparts-test.roche.com.cn  ",
          "webapp-imeal": "https://a1-imeal-test.roche.com.cn",
          "webapp-iwaimai": "https://a1-iwaimai-test.roche.com.cn",
          "webapp-mshop": "https://a1-mshop-test.roche.com.cn",
          "webapp-qshop": "http://a1-qshop-test.roche.com.cn",
          "webapp-ivenue": "https://a1-ivenue-test.roche.com.cn",
          "webapp-ivideo": "https://a1-ivideo-test.roche.com.cn",
          "webapp-icar": "https://a1-icar-test.roche.com.cn",
          "webapp-ijob": "https://a1-ijob-test.roche.com.cn",
          "webapp-ihelper": "https://a1-ihelper-test.roche.com.cn",
          "webapp-imyprofile": "https://a1-imyprofile-test.roche.com.cn",
          "webapp-idelegate": "http://a1-idelegate-test.roche.com.cn",
          "webapp-ishop": "http://a1-rshop-test.roche.com.cn",
          "signalrV2": "https://signalr-dev.smartmice.cn"
        }
        :
        {
          "passport": "https://passport.roche.com.cn",
          "gateway": "https://a1-gateway.roche.com.cn",
          "engine-api": "https://a1-gateway.roche.com.cn",
          "smartalipay": "https://a1-smartalipay.roche.com.cn",
          "signalr": "https://signalrx.smartmice.cn",
          "webapp-iaccount": "https://a1-iaccount.roche.com.cn",
          "webapp-ievent": "https://a1-ievent.roche.com.cn",
          "webpart-ievent": "https://a1-ievent-webparts.roche.com.cn  ",
          "webapp-imeal": "https://a1-imeal.roche.com.cn",
          "webapp-iwaimai": "https://a1-iwaimai.roche.com.cn",
          "webapp-mshop": "https://a1-mshop.roche.com.cn",
          "webapp-qshop": "https://a1-qshop.roche.com.cn",
          "webapp-ivenue": "https://a1-ivenue.roche.com.cn",
          "webapp-ivideo": "https://a1-ivideo.roche.com.cn",
          "webapp-icar": "https://a1-icar.roche.com.cn",
          "webapp-ijob": "https://a1-ijob.roche.com.cn",
          "webapp-ihelper": "https://a1-ihelper.roche.com.cn",
          "webapp-imyprofile": "https://a1-imyprofile.roche.com.cn",
          "webapp-idelegate": "https://a1-idelegate.roche.com.cn",
          "webapp-ishop": "https://a1-rshop.roche.com.cn",
          "signalrV2": "https://signalr.smartmice.cn"
        }
    AppInitialization.setUris(_uris)
  }
  // 设置皮肤颜色
  static setTheme(colorList) {
    colorList.map(item => {
      document.body.style.setProperty(item.attribute, item.value)
      SmartStorage.set(item.attribute,item.value)
    })
  }
  // 设置title
  static setTitle(txt = "A1") {
    document.title = txt
  }
  // 设置Uris
  static setUris(uris = {}) {
    SmartStorage.set("Uris", uris);
     // 获取过期时间
     let expiresTimes = "31104000s";
     let container=getCookie('container') || SmartStorage.get("container");
     if (container == "h5") {
         expiresTimes = "Session";
     }
     let config = { expires: expiresTimes, domain: window.idomain };
    setCookie("Uris", JSON.stringify({"Uris": uris}),config);
  }
  // url 参数转 storage
  static urlParamToStorage() {
    let url = window.location.search.substring(1);
    if(url){
      let urlParams = url.split('&');
      urlParams.forEach(item => {
        let temp = item.split('=');
        SmartStorage.set(temp[0], temp[1]);
      })
    }
  }

  static setiDomain() {
    let domain = document.domain;
    const re = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;//正则表达式
    let isIP = re.test(domain);
    let isLocalhost = domain == "localhost";
    domain = (isIP || isLocalhost) ? domain : domain.substring(domain.indexOf('.') + 1);
    window.idomain = domain;
  }
}

import iNotice from "./plugin.js";

import TPMService from './service.js';
export default class noticeController {

	constructor() {}

	static install() {
		return new Promise((resolve, reject) => {
			TPMService.get().then(response => {
				if(response && response.success) {
					resolve(response);
					this.openNotice(response);
				} else {
					resolve(response)
				}
			}).catch(error => {
				resolve(error)
			})
		})
	}

	static openNotice(response = {}) {
		const {
			content: {
				show = false,
				isShowBtn = true,
				title = "<p>提示</p>",
				content = "",
				actions = []
			} = {}
		} = response;
		if(!show) {
			return false;
		}
		iNotice.render({
			isShowBtn: isShowBtn,
			title: title,
			content: content,
			buttons: actions,
			onHandle: options => {
				console.log("notice options", options)
				if(options.eventCode in this) {
					this[options.eventCode](options)
				} else {
					this.hasRead()
				}
			}
		})
	}

	static hasRead(options = {}) {
		iNotice.close()
	}

}
class isonBlur {
  constructor() {
    this.delay = false
  }
  scrollTo0() {
    // alert(111)
    window.scrollTo(0,0)
    // document.body && (document.body.scrollTop = document.body.scrollTop);
  }
}
export default  isonBlur
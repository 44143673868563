<template>
	<section class="container">
		<transition name="dialog">
			<div v-if="isShow" class="dialog_container" ref='Sizer'>
				<p class="title_container">
					<span class="dialog_title">请选择会议状态</span>
					<i class="icon_cancle close" @click="close()"></i>
				</p>
				<main class="filter_container">
					<template v-for="(item,index) in filterList">
						<div @click="selectEventStatus(item)" :key="index" v-if="item.txt != '全部' && !loading" class="filter_item" :class="{isActive:item.isActive}">
							<i v-if="item.isActive" class="icon_select_confirm2"></i>
							<p class="filter_item_num">
								<!--<span class="filter_item_num_circle" :style="{background:item.itsExtData.color}">{{item.count}}</span>-->
								<span class="filter_item_num_circle" :style="{background: item.itsExtData && item.itsExtData.color ? item.itsExtData.color : '#CCCCCC'}">{{item.count}}</span>
							</p>
							<p class="filter_txt">{{item.txt}}</p>
						</div>
					</template>
				</main>
			</div>
		</transition>
		<div v-if="isShow" class="shade" @click="close()"></div>
	</section>
</template>

<script>
	import { mapMutations } from "vuex";
	export default {
		props: {
			isShow: {
				type: Boolean,
				default: false
			},
			data: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				loading: false,
				filterList: []
			}
		},
		watch: {
			isShow() {
				if(this.isShow) {
					this.QueryProposalStatus();
				}
			}
		},
		created() {

		},
		methods: {
			...mapMutations(["setEventStatusItem"]),
			//选择会议状态
			selectEventStatus(item) {
				this.loading = true;
				this.filterList.map((ele) => {
					ele.isActive = false;
				})
				item.isActive = true;
				this.loading = false;
				this.setEventStatusItem(item);
				setTimeout(() => {
					this.$emit('selectEventStatus');
				}, 100)
			},
			//关闭dialog
			close() {
				this.$emit('close', false)
			},
			//获取筛选状态
			async QueryProposalStatus() {
				let params = {
					isGroupTask: false,
				}
				let response = await this.api.post(this.url.mice.QueryProposalStatus, params);
				if(response && response.success) {
					this.filterList = response.content;
					this.data.map((ele) => {
						this.filterList.map((val) => {
							if(ele.txt == val.txt) {
								val.isActive = ele.isActive;
							}
						})
					})
				}
			}
		}
	}
</script>

<style scoped="scoped">
	.dialog_container {
		width: 100%;
		min-height: 52%;
        height: calc(100vh - 0.44rem);
        overflow-y: auto;
        padding-bottom: 1rem;
        box-sizing: border-box;
		/* border: 1px solid #CCCCCC; */
		/* box-shadow:0px 1px 8px #CCCCCC; */
		z-index: 25000;
		position: fixed;
		top: .44rem;
		left: 0px;
		background-color: white;
	}
	
	.title_container {
		position: relative;
	}
	
	.dialog_title {
		position: absolute;
		color: #999999;
		top: 0.1rem;
		left: .14rem;
	}
	
	.close {
		position: absolute;
		top: 0.1rem;
		right: .14rem;
	}
	
	.filter_container {
		padding-top: .25rem;
	}
	
	.filter_item {
		position: relative;
		display: inline-block;
		box-sizing: content-box;
		padding-top: 0.1rem;
		width: 33%;
		float: left;
		height: 11vh;
		text-align: center;
		line-height: 11vh;
		margin-top: 0.08rem;
		overflow: hidden;
	}
	
	@media(min-width:768px) {
		.filter_item {
			width: 25%;
			margin-top: 0.15rem;
		}
		.dialog_container {
			padding-bottom: .3rem;
		}
	}
	
	.isActive {
		background-color: #F2F8FF;
	}
	
	.filter_item_num {
		position: absolute;
		width: 100%;
		height: 7vh;
		line-height: 6vh;
		top: 0.1rem;
		left: 0px;
	}
	
	.filter_item_num_circle {
		display: inline-block;
		width: 6.3vh;
		height: 6.3vh;
		line-height: 6.3vh;
		border-radius: 50%;
		color: white;
		font-size: .15rem;
		/* box-shadow:1px 1px 2px #666666; */
	}
	
	.filter_item_num_circle:active {
		width: 6vh;
		height: 6vh;
		font-size: .14rem;
		line-height: 6vh;
	}
	
	.filter_txt {
		font-size: .14rem !important;
		position: absolute;
		width: 100%;
		height: 4vh;
		line-height: 4vh;
		bottom: 0px;
		left: 0px;
		font-size: .14rem;
	}
	
	.shade {
		position: fixed;
		bottom: 0%;
		left: 0%;
		width: 100%;
		height: calc(100% - .44rem);
		background-color: black;
		z-index: 24500;
		-moz-opacity: 0.5;
		opacity: 0.50;
		filter: alpha(opacity=70);
	}
	/*dialog出现的动画*/
	
	.dialog-enter-active {
		animation: dialog-show 0.25s;
	}
	
	.dialog-leave-active {
		animation: dialog-hide 0.45s;
	}
	
	@keyframes dialog-show {
		from {
			top: -52%;
			opacity: 0.3;
		}
		to {
			top: 0.44rem;
			opacity: 1;
		}
	}
	
	@keyframes dialog-hide {
		from {
			top: 0.44rem;
			opacity: 1;
		}
		to {
			top: -52%;
			opacity: 0;
		}
	}
</style>

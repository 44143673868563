import Vue from 'vue';
import Component from "vue-class-component";
import { getCookie, getAllCookies , removeCookie} from 'tiny-cookie';
import router from "@/router";
@Component
class myCenter extends Vue {

    urllogout = ''

    async created() {
    }
    setLoginUrl () {
        const rnd = new Date().getTime();
        const version = getCookie("version") || this.SmartStorage.get("version");
        const platform = getCookie("platform") || "app";
        const Uris = this.SmartStorage.get("Uris") || {}
        const container = getCookie("container")
        this.urllogout =  `${Uris["webapp-iaccount"]}/?tenant=roche&platform=${platform}&removeCookie=true&srcVersion=68&rnd=${rnd}`
        if(version) {
            this.urllogout += `&version=${version}`
        }
        if(container) {
            this.urllogout += `&container=${container}`
        }
    }
    setiDomain() {
        let domain = document.domain;
        var re = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/; //正则表达式
        let isIP = re.test(domain);
        let isLocalhost = domain === "localhost";
        domain = isIP || isLocalhost ? domain : domain.substring(domain.indexOf(".") + 1);
        window.idomain = domain;
    }
    removeAllCookies(cookieList = []) {
        if (!cookieList.length) {
            cookieList = Object.keys(getAllCookies());
        }
        Object.keys(cookieList).forEach(function(key) {
            removeCookie(cookieList[key], {
                domain: window.idomain,
            });
            removeCookie(cookieList[key], {
                domain: document.domain,
            });
        });
    }
    async deleteLocalData() {
        this.SmartStorage.clear();
        await this.removeAllCookies();
        localStorage.clear();
        sessionStorage.clear();
    }
    /**
     * get request sso logout url
     */
    async logoutAsync(code) {
        this.setLoginUrl()
        const iEdition = getCookie("container");
        if (iEdition && (iEdition == "h5" || iEdition == "browser")) {
            this.setiDomain()
            this.deleteLocalData()
            if ([401].includes(code)) {
                router.push({
                    path: '/error'
                })
            } else {
                setTimeout(() => {
                    window.open(this.urllogout, "_self");
                }, 200);
            }
        } else {
            if (window.flutter_inappwebview) {
                await window.flutter_inappwebview.callHandler("App.clearAppData", '');
                this.clearCacheCallback(this.urllogout);
            } else {
                let _this = this
                this.iJsBridge.call({
                    method: "SMGeneral.clearCache",
                    callback: _this.clearCacheCallback(_this.urllogout)
                });
            }
        }
    }
    clearCacheCallback() {
        if (window.flutter_inappwebview) {
            let params = {
                "url": this.urllogout,
            };
            window.flutter_inappwebview.callHandler("SmGeneral.openNewWebSiteAndRemove", JSON.stringify(params));
        } else {
            this.iJsBridge.openWebapp({
                loadUrl: this.urllogout,
                ignoreCookie: true,
            });
            this.iJsBridge.close();
        }
    }
}

export default myCenter;

import "babel-polyfill"
import { SmartHttp, SmartStorage } from "smart-core-util";
import iJsBridge from 'smart-javascript-bridge'
// iNotice
import noticeController from "@/components/iNotice/controller.js";
noticeController.install();
import GetApiVersion from '@/controllers/getApiVersion.js';
import CacheData from "@/controllers/cache-data.js"
import AppInitialization from "@/controllers/app-initialization.js"
import Vue from 'vue';
import App from './App';
import router from './router';
//mint-UI
import MintUI from 'mint-ui';
Vue.use(MintUI)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// ui组件
import SmartUI from 'smart-ui'
import 'smart-ui/lib/index.css'
Vue.use(SmartUI);
// 引入rem.js
import './assets/js/rem'

//公共css样式
import '@/assets/css/public.css';
import 'vant/lib/index.css';
//日期格式化
import { formatDate } from "@/common/formatDate.js";
//当前时间
import { formatTime } from "@/common/formatTime.js";
//日期比较
import { contrastDate, contrastDate2 } from "@/common/contrastDate.js";
//金额格式化
import { formatMoney } from '@/common/accounting.js';
import '@/utils/filter/filter' // 全局filter
//金额转化为number
import { Amount } from '@/common/Amount.js';
//延时输入搜索调用方法
import { delaySearch } from '@/common/delaySearch.js';
import './common/filter' // 全局filter

import url from './apis/urs'
//注入全局组件
import '@/components/install.js';

//Vuex
import store from './store.js';

import isonBlur from '@/common/onBlur'
import smartFixInput from 'smart-fixinput';
Vue.use(smartFixInput)
import Viewer from 'v-viewer'; // 图片预览
import 'viewerjs/dist/viewer.css';
Vue.use(Viewer);
// 引入cookie
import * as tinyCookie from 'tiny-cookie'
import myCenter from "@/scripts/myCenter";

import moment from "moment";

Vue.prototype.$moment = moment


Vue.prototype.api = new SmartHttp({
	baseURL: process.env.VUE_APP_GATEWAY,
	alert: MintUI.Toast,
    handle401: function() {
      myCenter.logoutAsync(401)
    }
}).request
Vue.prototype.url = url;
Vue.prototype.contrastDate = contrastDate;
Vue.prototype.contrastDate2 = contrastDate2;
Vue.prototype.formatDate = formatDate;
Vue.prototype.formatTime = formatTime;
Vue.prototype.formatMoney = formatMoney;
Vue.prototype.Amount = Amount;
Vue.prototype.delaySearch = delaySearch;
Vue.prototype.iJsBridge = new iJsBridge({
	alert: MintUI.Toast
});
Vue.prototype.$cookies = tinyCookie;
Vue.prototype.SmartStorage = SmartStorage;
Vue.prototype.$eventHub = Vue.prototype.$eventHub || new Vue();
Vue.prototype.isonBlur = isonBlur;

Vue.config.productionTip = false;

/* eslint-disable no-new */
Promise.resolve().then(() => {
  return CacheData.install()
}).then(() => {
  return AppInitialization.install()
}).then(() => {
  GetApiVersion.install()
}).then(() => {
    new Vue({
      router,
      store,
      render: (h) => h(App),
      created: function() {
        console.log('webapp created');
        console.log(this.$route)
      },
      mounted: function() {
        window.addEventListener("message", this.receiveMessage, false);
      },
      methods: {
        receiveMessage: function(e) {
          if(!e.data || !e.data || e.data.from != "simulator") return;
          SmartStorage.setAll(e.data.sessionData);
          Vue.prototype.api.baseURL = process.env.VUE_APP_GATEWAY
        }
      }
    }).$mount('#app')

})

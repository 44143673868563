import { MessageBox, Toast } from "mint-ui";
import Vue from 'vue';

const ieventPolicy = {
	data() {
		return {
            itemTips:'',
            tipList:[]
		}
	},
	created() {
		
	},
	methods: {
        async getApiVersion(){
            let params = 
            {
                "collection": "cfg-api-version",
                "filter": {
                    "platform": "app",
                    "tenantCode": this.$cookies.get("tenant"),
                    "userIds": {
                        "$in": [this.$cookies.get('userId'), "*"]
                    }
                },
                "projection": {
                    "apiVersion": 1,
                    "userIds": 1
                },
                "sort": {
                    "sort": 1
                }
            }
            let res = await this.api.post(this.url.tpm.getsettings, params);
          if (res.success) {
              this.$cookies.set("apiVersion",res.content.apiVersion);
              this.SmartStorage.set("apiVersion",res.content.apiVersion);
          }

        }
	}
}

Vue.mixin(ieventPolicy)

export default ieventPolicy;
